import React, { useEffect, useState } from "react";

import Http from "../../security/Http";
import url from "../../../Development.json";
import CommonLoader from "../../AppLoaders/CommonLoader";

const PartnersHeader = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [announcements, setAnnouncements] = useState([]);

  const getAnnouncements = () => {
    setIsLoading(true);
    Http.callApi(url.get_home_page_announcements).then((response) => {
      console.log(response.data.data);
      setAnnouncements(response.data.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getAnnouncements();
  }, []);

  return (
    <>
      {!isLoading ? (
        <section className="py-32 relative bg-opalPurple">
          {/* Overlay with darker background */}
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>

          {/* Ensure the content of your section is not affected by the overlay by setting it relative */}
          <div className="container mx-auto relative">
            <div className="px-4 md:px-0">
              <h1 className="text-3xl md:text-4xl text-center md:text-start tracking-wide tracking-wide font-semibold mb-3 text-white">
                Business Affiliates & Exclusive Offers
              </h1>
              {/* Example of text color adjustment */}
              <h2 className="text-base md:text-xl text-center md:text-start tracking-wide text-white">
                Become a member now to get access to exclusive offers provided
                by our Business Affiliates.
              </h2>
            </div>
          </div>
        </section>
      ) : (
        <div className="flex flex-col py-16 w-screen overflow-y-hidden justify-center items-center">
          <CommonLoader />
          <p className="font-medium">Updating Announcements</p>
        </div>
      )}
    </>
  );
};

export default PartnersHeader;
