import axios from "axios";
// import Security from "./Security";
import { configHeaderAxios } from "../Helpers/response";

function AxiosMiddleware(method, url, data) {
  var options = configHeaderAxios();
  switch (method) {
    case "get":
      return axios.get(url, { ...options, params: data });
    case "post":
      return axios.post(url, data, options);
    case "head":
      return axios.head(url, data, options);
    case "patch":
      return axios.patch(url, data, options);
    case "put":
      return axios.put(url, data, options);
    case "delete":
      return axios.delete(url, { ...options, params: data });
    default:
      break;
  }
}
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["X-CSRF-TOKEN"] = "token.content";
// axios.interceptors.response.use(
//   (response) => {
//     if (response.data.mac !== undefined) {
//       response.data = new Security().decrypt(response.data);
//     }
//     return response;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

export function callApi(url, data = []) {
  var options = configHeaderAxios();
  return AxiosMiddleware(
    url[0],
    "https://api.opalempire.com.au/" + url[1],
    data,
    options
  );
}

export function setBearerToken(token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
export function setVerifyToken(token) {
  axios.defaults.headers.common["VerifyToken"] = `${token}`;
}
export function setLocalizationLanguage(language) {
  axios.defaults.headers.common["X-localization"] = `${language}`;
}
