import React, { useEffect, useState } from "react";
import CommonLoader from "../../AppLoaders/CommonLoader";
import { Link, useLocation } from "react-router-dom";
import Http from "../../security/Http";
import HomePackageItem from "../../common/HomePackageItem";

const HomeMemberships = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    getGiveawayPackages();
  }, []);

  const getGiveawayPackages = () => {
    Http.callApi(["get", `item/giveaway/packages/get`]).then((response) => {
      console.log(response.data);
      if (response.data.success === true) {
        setPackages(response.data.data);
      } else {
        alert(response?.data.message ?? "");
      }
    });
  };

  const createGiveawayRecord = (packageId) => {
    const queryParams = new URLSearchParams(location.search);
    const referral = queryParams.get("referral");

    Http.callApi(["post", `item/giveaway/package/record/create`], {
      packageId: packageId,
      referralId: referral ?? "",
    }).then((response) => {
      console.log(response.data);
      if (response.data.success === true) {
        console.log("Created Package Transaction Record: ", response.data.data);
        window.location.href = `/purchase/giveaway/${
          response?.data?.data?._id ?? ""
        }`;
      } else {
        alert(response?.data.message ?? "");
      }
    });
  };

  return isLoading ? (
    <div className="flex flex-col bg-opalDarkBlue py-16 w-screen overflow-y-hidden justify-center items-center">
      <CommonLoader />
      <p className="font-medium text-white">Fetching Membership Levels</p>
    </div>
  ) : (
    <div className="bg-opalDarkBlue">
      <div className="container mx-auto px-4 px-md-0 py-8 md:py-16">
        <div className="flex flex-col justify-start items-start mb-3">
          <h1 className="font-semibold text-white text-2xl md:text-3xl">
            Join the Opal Empire Elite Club
          </h1>

          <p className="text-base md:text-xl tracking-wide text-white mt-2 md:mt-2">
            By joining our exclusive club, you'll gain access to a wealth of
            educational content, exciting monthly giveaways, and special
            discounts from our partnered brands. Discover a world of knowledge,
            opportunities, and savings that are tailored to enhance your
            experience as a member of the Opal Empire Elite Club.
          </p>

          <p className="text-lg md:text-2xl tracking-wide font-medium text-white mt-2 md:mt-4">
            Join us today to unlock a world of benefits!
          </p>
        </div>

        <div className="mt-8 flex flex-row">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            {packages &&
              packages.map((item) => (
                <>
                  <HomePackageItem
                    key={item._id}
                    item={item}
                    onSubscribePress={createGiveawayRecord}
                  />
                </>
              ))}
          </div>
        </div>
      </div>

      {/* <div className="mt-8">
        <a
          className="w-full"
          href="https://opalempire.com.au/giveaway/gemmasta-gmp2"
        >
          <div className="mt-4 z-[200] bg-opalBlue hover:bg-platinum transition-all rounded-xl">
            <p className="text-center text-base md:text-lg text-white font-semibold tracking-wider py-3 md:py-4">
              BECOME A MEMBER TODAY
            </p>
          </div>
        </a>
      </div> */}
    </div>
  );
};

export default HomeMemberships;
