import About from "../Pages/Static/About";
import Stores from "../Pages/Stores/Stores";
import Art from "../Pages/Art/Art";
import Home from "../Pages/Home/Home";
import Podcasts from "../Pages/Podcasts/Podcasts";
import Privacy from "../Pages/Static/Privacy";
import Terms from "../Pages/Static/Terms";
import Cart from "../Pages/Cart/Cart";
import Sitemap from "../Pages/Static/Sitemap";
import Product from "../Pages/Products/Product";
import Checkout from "../Pages/Checkout/Checkout";
import Chat from "../Pages/Chat/Chat";
import Login from "../Pages/Authentication/Login";
import SearchResult from "../Pages/SearchResult/SearchResult";
import VendorProfile from "../Pages/VendorProfile/VendorProfile";
import Wishlist from "../Pages/User/Wishlist";
import UserOrder from "../Pages/User/UserOrder";
import UserProfile from "../Pages/User/UserProfile";
import ForgetPasswod from "../Pages/Authentication/ForgotPassword";
import Jewellery from "../Pages/Jewellery/Jewellery";
import OrderDetailsPage from "../Pages/OrderDetails/OrderDetailsPage";
import OrderProcessing from "../Pages/OrderProcessing/OrderProcessing";
import Memberships from "../Pages/Memberships/Memberships";
import SingleGiveaway from "../Pages/Giveaways/SingleGiveaway";
import ExclusiveContent from "../Pages/ExclusiveContent/ExclusiveContent";
import PurchaseGiveaway from "../Pages/Giveaways/PurchaseGiveaway";
import Merchandise from "../Pages/Merchandise/Merchandise";
import PurchaseSuccessful from "../Pages/Subscriptions/PurchaseSuccess";
import Partners from "../Pages/Partners/Partners";

const CustomerRoutes = [
  {
    element: <Home />,
    index: true,
    path: "/",
    auth: false,
  },
  {
    path: "art",
    auth: false,
    element: <Art />,
  },
  {
    path: "jewellery",
    auth: false,
    element: <Jewellery />,
  },
  {
    path: "memberships",
    auth: false,
    element: <Memberships />,
  },
  {
    path: "search",
    auth: false,
    element: <SearchResult />,
  },
  {
    path: "processing",
    auth: true,
    element: <OrderProcessing />,
  },
  {
    path: "order",
    auth: true,
    element: <OrderDetailsPage />,
  },
  {
    path: "product",
    auth: false,
    element: <Product />,
  },
  {
    path: "podcasts",
    auth: false,
    element: <Podcasts />,
  },
  {
    path: "stores",
    auth: false,
    element: <Stores />,
  },
  {
    path: "vendor",
    auth: false,
    element: <VendorProfile />,
  },
  {
    path: "aboutus",
    auth: false,
    element: <About />,
  },
  {
    path: "privacy-policy",
    auth: false,
    element: <Privacy />,
  },
  {
    path: "terms-and-conditions",
    auth: false,
    element: <Terms />,
  },
  {
    path: "privacy",
    auth: false,
    element: <Privacy />,
  },
  {
    path: "terms_conditions",
    auth: false,
    element: <Terms />,
  },
  {
    path: "cart",
    auth: true,
    element: <Cart />,
  },
  {
    path: "chat/:name",
    element: <Chat />,
    auth: true,
  },
  {
    path: "chat",
    element: <Chat />,
    auth: true,
  },
  {
    path: "sitemap",
    auth: true,
    element: <Sitemap />,
  },
  {
    path: "checkout",
    auth: true,
    element: <Checkout />,
  },
  {
    path: "login",
    auth: false,
    element: <Login />,
  },
  {
    path: "forget-password",
    auth: false,
    element: <ForgetPasswod />,
  },
  {
    path: "profile",
    auth: true,
    element: <UserProfile />,
  },
  {
    path: "wishlist",
    auth: true,
    element: <Wishlist />,
  },
  {
    path: "orders",
    auth: true,
    element: <UserOrder />,
  },
  {
    path: "education",
    auth: false,
    element: <ExclusiveContent />,
  },
  {
    path: "giveaway/:name",
    auth: false,
    element: <SingleGiveaway />,
  },
  {
    path: "purchase/giveaway/:id",
    auth: false,
    element: <PurchaseGiveaway />,
  },
  {
    path: "subscription/purchase/success",
    auth: false,
    element: <PurchaseSuccessful />,
  },
  {
    path: "merchandise",
    auth: false,
    element: <Merchandise />,
  },
  {
    path: "partners",
    auth: false,
    element: <Partners />,
  },
];

export default CustomerRoutes;
