import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

import { MdOutlineShoppingBag } from "react-icons/md";

import { FaRegUserCircle } from "react-icons/fa";
import Http from "../security/Http";
import url from "../../Development.json";
import eventEmitter from "../Events/EventEmitter";
import useLocalStorage from "../../hook/useLocalStorage";
import usePersistentUUID from "../../hook/persistentUUID";

const Header = () => {
  const deviceUUID = usePersistentUUID();
  const [profileId] = useLocalStorage("profileId", "");
  const [profile, setProfile] = useState(null);
  const [cart, setCart] = useState([]);

  const updateCart = () => {
    Http.callApi(url.get_cart, {
      userid: profileId,
      deviceUUID: deviceUUID,
    }).then((response) => {
      console.log("Cart Data: ", cart);
      setCart(response.data);
      setTimeout(() => {
        window.scrollTo({ behavior: "smooth", top: 0 });
      }, 250);
    });
  };

  useEffect(() => {
    // Define the function you want to use as the event listener
    const handleUpdateUser = (newState) => {
      getProfile();
    };

    const handleUpdateCart = (newState) => {
      updateCart();
    };

    // Add the listener
    eventEmitter.addListener("updateUser", handleUpdateUser);
    eventEmitter.addListener("updateCartCount", handleUpdateCart);

    // Return a cleanup function that removes the listener
    return () => {
      eventEmitter.removeListener("updateUser", handleUpdateUser);
      eventEmitter.removeListener("updateCartCount", handleUpdateCart);
    };
  }, []);

  const getProfile = () => {
    const customerToken = localStorage.getItem("customer_access_token");
    console.log("CUSTOMER TOKEN:", customerToken);
    console.log("USER PROFILE", profile);
    if (customerToken) {
      Http.callApi(url.customer_profile).then((response) => {
        setProfile(response.data);
      });
    } else {
      setProfile(null);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <header className="sticky top-0 left-0 w-full bg-white z-[2000]">
        <div className="container mx-auto px-4 flex flex-row justify-center items-center">
          <Link to="/">
            <img
              src={require("../../assets/images/OpalRoundWeb.png")}
              alt="Opal Empire"
              className="h-24 md:h-32 py-2"
            />
          </Link>
          <div style={{ flex: 1 }} />
          <nav className="justify-center items-center hidden md:flex">
            <ul className="flex justify-center items-center">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "h-full justify-center items-center px-8 py-8 tracking-wide text-opalBlue hover:text-opalBlue transition-all md:text-base font-medium"
                    : "h-full justify-center items-center px-8 py-8 tracking-wide md:text-base hover:text-opalBlue transition-all font-normal"
                }
              >
                Home
              </NavLink>

              <NavLink
                to="/podcasts"
                className={({ isActive }) =>
                  isActive
                    ? "h-full justify-center items-center px-8 py-8 tracking-wide text-opalBlue hover:text-opalBlue transition-all md:text-base font-medium"
                    : "h-full justify-center items-center px-8 py-8 tracking-wide md:text-base hover:text-opalBlue transition-all font-normal"
                }
              >
                Streams
              </NavLink>

              <NavLink
                to="/art"
                className={({ isActive }) =>
                  isActive
                    ? "h-full justify-center items-center px-8 py-8 tracking-wide text-opalBlue hover:text-opalBlue transition-all md:text-base font-medium"
                    : "h-full justify-center items-center px-8 py-8 tracking-wide md:text-base hover:text-opalBlue transition-all font-normal"
                }
              >
                Art
              </NavLink>

              <NavLink
                to="/jewellery"
                className={({ isActive }) =>
                  isActive
                    ? "h-full justify-center items-center px-8 py-8 tracking-wide text-opalBlue hover:text-opalBlue transition-all md:text-base font-medium"
                    : "h-full justify-center items-center px-8 py-8 tracking-wide md:text-base hover:text-opalBlue transition-all font-normal"
                }
              >
                Jewellery
              </NavLink>

              <NavLink
                to="/partners"
                className={({ isActive }) =>
                  isActive
                    ? "h-full justify-center items-center px-8 py-8 tracking-wide text-opalBlue hover:text-opalBlue transition-all md:text-base font-medium"
                    : "h-full justify-center items-center px-8 py-8 tracking-wide md:text-base hover:text-opalBlue transition-all font-normal"
                }
              >
                Discounts
              </NavLink>

              {/* <NavLink
                to="/memberships"
                className={({ isActive }) =>
                  isActive
                    ? "h-full justify-center items-center px-8 py-8 tracking-wide text-opalBlue hover:text-opalBlue transition-all md:text-base font-medium"
                    : "h-full justify-center items-center px-8 py-8 tracking-wide md:text-base hover:text-opalBlue transition-all font-normal"
                }
              >
                Memberships
              </NavLink> */}
            </ul>
          </nav>

          <div style={{ flex: 1 }} />

          <div className="flex flex-row justify-center items-center">
            <NavLink
              to="/cart"
              className={({ isActive }) => (isActive ? "text-opalBlue" : "")}
            >
              <div className="relative">
                <div className="h-4 w-4 z-[200] px-1 py-1 top-[-4px] right-[-6px] absolute rounded-full flex justify-center items-center bg-opalBlue text-white">
                  <p className="text-xs font-semibold">
                    {cart?.items?.length ?? "0"}
                  </p>
                </div>
                <MdOutlineShoppingBag className="relative text-2xl md:text-2xl" />
              </div>
            </NavLink>

            <div className="bg-black h-[24px] w-[1px] ml-4 md:ml-6 mr-4 md:mr-8" />

            {profile ? (
              <>
                <div className="relative">
                  <Link
                    className="flex flex-row justify-center items-center"
                    to={"/profile"}
                  >
                    <img
                      src={profile?.image ?? ""}
                      alt={profile?.first_name ?? ""}
                      className="w-8 h-8 rounded-full"
                    />
                    <p className="ml-2 hidden md:flex font-medium text-sm md:text-base tracking-wide">
                      {profile?.first_name ?? ""}
                    </p>
                  </Link>
                </div>
              </>
            ) : (
              <>
                <NavLink
                  to="/login"
                  className="flex flex-row bg-opalLightBlue hover:bg-opalBlue text-opalBlue hover:text-white transition-all px-3 md:px-6 py-3 md:py-2 rounded-full md:rounded-xl"
                >
                  <p className="text-sm md:text-base font-medium hidden md:flex">
                    Login
                  </p>
                  <FaRegUserCircle className="text-lg flex md:hidden" />
                </NavLink>
              </>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
