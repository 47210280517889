import React, { useEffect, useState } from "react";
import PartnerItem from "../../common/PartnerItem";
import Http from "../../security/Http";
import CommonLoader from "../../AppLoaders/CommonLoader";
import Footer from "../../Layouts/Footer";
import AffiliateModal from "../../common/Modals/AffiliateModal";

const PopularPartners = () => {
  const [loading, setLoading] = useState(true);
  const [partners, setPartners] = useState([]);

  const [selectedPartnerId, setSelectedPartnerId] = useState("");
  const [showPartner, setShowPartner] = useState(false);

  useEffect(() => {
    getPartners();
  }, []);

  const getPartners = () => {
    setLoading(true);
    Http.callApi(["get", `item/partners/get`]).then((response) => {
      console.log(response.data);
      if (response.data.success === true) {
        setPartners(response.data.data);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      } else {
        alert(response?.data.message ?? "");
      }
    });
  };

  return (
    <>
      <AffiliateModal
        affiliateId={selectedPartnerId}
        isOpen={showPartner}
        onClose={() => setShowPartner(false)}
      />
      <div className="container mx-auto px-4 md:px-0 py-8 md:py-16">
        {/* Popular Partners */}
        <div className="flex flex-row justify-center items-center">
          <p className="font-semibold text-2xl">Popular Affiliates</p>

          <div style={{ flex: 1 }} />

          <p className="font-normal text-sm text-midGrey">View All</p>
        </div>

        {/* Partner Items */}
        {!loading ? (
          <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mt-4">
            {partners && partners.length > 0
              ? partners.map((partner) => (
                  <>
                    <PartnerItem
                      item={partner}
                      onClickPartner={(id) => {
                        setSelectedPartnerId(id);
                        setShowPartner(true);
                      }}
                    />
                  </>
                ))
              : null}
          </div>
        ) : (
          <>
            <div className="flex flex-col items-center justify-center">
              <CommonLoader />
            </div>
          </>
        )}
      </div>

      <Footer />
    </>
  );
};

export default PopularPartners;
